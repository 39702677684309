import React, { PureComponent } from 'react';
import AgentForm from '../components/YchAgentForm';
import md5 from '@/utils/md5';
import Layout from '@/layout/Layout';
import { hideLoading, showLoading } from '@/components/message/message';
import { YchApi } from '@/web/api';

export default class YchAgentCreate extends PureComponent<RouterPropsType> {
  state = {
    loading: false
  };

  private form: AgentForm | null = null

  render() {
    const { loading } = this.state;
    return (
      <Layout.FooterBar onOk={this.clickSave} loading={loading}>
        <AgentForm ref={r => this.form = r} mode="create"/>
      </Layout.FooterBar>
    )
  }

  clickSave = () => {
    this.form?.validateFields().then((values: any) => {
      showLoading(0);
      YchApi.agent_create({
        ...values,
        password: md5.hex_md5(values.password),
      })
      .then(() => {
        showSuccess.save();
        this.props.history.close();
      }).finally(() => {
        hideLoading();
      });
    })
  }
}
