import React, { PureComponent } from 'react';
import { Form, Row, Col, Input, Select, Cascader, Modal, Button } from 'antd';
import cities from '@/utils/cities';
const styles = require('./agent-form.less');
import { FormInstance } from 'antd/lib/form';
import { YchApi } from '@/web/api';

export default class YchAgentForm extends PureComponent<{
  initialValues: any
  mode: 'edit' | 'create'
  onReset?(): void
}> {
  static defaultProps = {
    initialValues: false,
  };

  private form: FormInstance | null = null

  private formData = {
    agentName: '',
    phone: '',
    webUrl: '',
    employeeId: '',
    addressId: []
  }

  private address = {
    provinceCode: '',
    provinceName: '',
    cityCode: '',
    cityName: '',
    areaCode: '',
    areaName: ''
  }

  state = {
    visible: false,
    employees: [],
  }

  constructor(props) {
    super(props);

    if (this.props.initialValues !== false) {
      this.formData = { ...this.props.initialValues };
      const {provinceCode, provinceName, cityCode, cityName, areaCode, areaName} = this.props.initialValues;
      this.formData.addressId = ([provinceCode, cityCode, areaCode] as any);

      this.address.provinceCode = provinceCode;
      this.address.provinceName = provinceName;
      this.address.cityName = cityName;
      this.address.cityCode = cityCode;
      this.address.areaCode  = areaCode;
      this.address.areaName = areaName;
    }
  }

  componentDidMount() {
    YchApi.employee_list()
    .then(res => {
      this.setState({
        employees: res,
      });
    })
  }

  render() {
    
    const { mode } = this.props;

    return (
      <div className="agent-form">
        <Form labelCol={{span: 6}} ref={r => this.form = r} initialValues={{
          status: '1',
          ...this.formData
        }}>
          <div className={styles.title}>基础信息</div>
          <Row>
            <Col span={12}>
              <Form.Item label="名称" name="agentName" rules={[
                {
                  required: true,
                  message: '请填写名称',
                },
              ]}>
                <Input placeholder="填写" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <div className={styles.agentRow}>
                <Form.Item label="业务员" name="employeeId" rules={[
                  {
                    required: true,
                    message: '请选择业务员',
                  },
                ]}>
                  <Select style={{width: '100%'}} placeholder="选择" onClick={() => {
                    
                  }}>
                    {
                      this.state.employees.map((item: any) => {
                        return <Select.Option key={item.employeeId} value={item.employeeId}>{item.fullName}</Select.Option>
                      })
                    }
                  </Select>
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item label="级别" name="agentType" rules={[
                {
                  required: true,
                  message: '请选择级别',
                },
              ]}>
                <Select placeholder="选择">
                  {[
                    {
                      value: '2',
                      name: '市级'
                    },
                    {
                      value: '3',
                      name: '县(区)级'
                    }
                  ].map(item => {
                    return <Select.Option value={item.value} key={item.value}>{item.name}</Select.Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="区域" name="addressId" rules={[{
                required: true,
                message: '请选择区域',
              }]}>
                <Cascader
                  options={cities}
                  placeholder="选择"
                  onChange={this.onCascaderChange}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item label="详细地址" name="street" labelCol={{span: 3}} rules={[
                {
                  required: true,
                  message: '请填写详细地址',
                },
              ]}>
                <Input placeholder="街道/门牌号" style={{width: '100%'}}/>
              </Form.Item>
            </Col>
          </Row>
          {/* <Row>
            <Col span={24}>
              <Form.Item label="介绍" name="compDesc" labelCol={{span: 3}}>
                <Input placeholder="填写" />
              </Form.Item>
            </Col>
          </Row> */}
          {/* <Row>
            <Col span={12}>
              <Form.Item label="启用/禁用" name="status">
                <Select>
                  <Select.Option value="1">启用</Select.Option>
                  <Select.Option value="0">禁用</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="邮编" name="compZipCode">
                <Input placeholder="填写"/>
              </Form.Item>
            </Col>
          </Row> */}
          {/* <Row>
            <Col span={12}>
              <Form.Item label="qq" name="qq">
                <Input placeholder="填写" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="邮箱" name="email">
                <Input placeholder="填写" />
              </Form.Item>
            </Col>
          </Row> */}
          {/* <Row>
            <Col span={12}>
              <Form.Item label="传真" name="compFax">
                <Input placeholder="填写" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="网址" name="compWebUrl">
                <Input placeholder="填写" />
              </Form.Item>
            </Col>
          </Row> */}

          <div className={styles.title}>账号信息</div>
          <Row>
            <Col span={12}>
              <Form.Item label="联系人" name="linkman" rules={[
                {
                  required: true,
                  message: '请填写联系人',
                },
              ]}>
                <Input placeholder="填写" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="手机号码" name="mobile" rules={[
                {
                  required: true,
                  message: '请填写手机号码',
                },
                {
                  min: 11,
                  message: '请填写正确的手机号码',
                },
              ]}>
                <Input placeholder="填写" suffix={<div style={{color: 'orange'}}>*将作为后台登录账号</div>} maxLength={11}/>
              </Form.Item>
            </Col>
          </Row>
          {
            mode === 'edit' &&
            <Row>
              <Col span={12}>
                <Form.Item label="登录密码">
                  <Button type="default" icon={<i className="iconfont icon-zhongzhi"/>} onClick={() => {
                    this.props.onReset && this.props.onReset();
                  }}>重置密码</Button>
                </Form.Item>
              </Col>
            </Row>
          }
          {
            mode !== 'edit' &&
            <Row>
              <Col span={12}>
                <Form.Item label="登录密码" name="password" rules={[
                  {
                    required: true,
                    message: '请填写登录密码',
                  },
                  {
                    min: 6,
                    message: '密码最少需6位',
                  },
                ]}>
                  <Input.Password placeholder="填写" maxLength={25}/>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="确认密码" name="confirmPassword" rules={[
                  {
                    required: true,
                    message: '请填写确认密码',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('两次密码填写不一致!'));
                    },
                  }),
                ]}>
                  <Input.Password placeholder="填写" />
                </Form.Item>
              </Col>
            </Row>
          }

          <div className={styles.title}>服务支持</div>
          <Row>
            <Col span={12}>
              <Form.Item label="服务热线" name="servicePhone" rules={[
                {
                  required: true,
                  message: '请填写服务热线',
                },
              ]}>
                <Input placeholder="填写" />
              </Form.Item>
            </Col>
          </Row>

          {/* <div className={styles.title}>财务信息</div>
          <Row>
            <Col span={12}>
              <Form.Item label="纳税人识别号" name="taxNumber">
                <Input placeholder="填写" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="发票抬头">
                <Input placeholder="填写" />
              </Form.Item>
            </Col>
          </Row> */}
        </Form>
      </div>
    )
  }

  onCascaderChange = (value, selectedOptions) => {
    const province = selectedOptions[0];
    const city = selectedOptions[1];
    const area = selectedOptions[2];

    const result = {
      provinceCode: province.value,
      provinceName: province.label,
      cityCode: city.value,
      cityName: city.label,
      areaCode: area.value,
      areaName: area.label,
    };
    this.address = { ...result };
  }

  validateFields = async () => {
    const values = await this.form?.validateFields();
    const { provinceCode, provinceName, cityCode, cityName, areaCode, areaName } = this.address;
    return {
      ...values,
      provinceCode,
      provinceName,
      cityCode,
      cityName,
      areaCode,
      areaName,
      addressId: undefined,
      confirmPassword: undefined,
    };
  }
}
